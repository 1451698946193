import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { Grid } from '../../StyleGuide';
import { useUser } from '../../authentication';
import useCurriculum from '../../hooks/useCurriculum';
import useAngularScope from '../../hooks/useAngularScope';
import useCurriculumOnboardingDatastore from '../../hooks/useCurriculumOnboardingDatastore';
import userService from '../../services/userService';
import windowService from '../../services/windowService';
import LoadingState from '../../components/LoadingState';
import ErrorMessage from '../../components/ErrorMessage';
import orgService from '../../services/orgService';
import { generateUUID } from '../../services/uuid';
import { handleError } from '../../utils/apiUtils';
import licenseService from '../../services/licenseService';
import cacheService from '../../services/cacheService';
import { ContentContainer } from './OnboardingElements';
import AgeGroupsView from './AgeGroupsView';
import EmailView from './EmailView';
import ChurchView from './ChurchView';
import alertService from '../../services/AlertService';
import useEventTracking from '../../hooks/useEventTracking';
import { LinkStyleButton, PrimaryButton } from '../../components/Buttons';

const BodyStyle = createGlobalStyle`
  body {
    background: ${props => props.background} !important;
    height: 100vh;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  margin: ${Grid._4} 0;

  img {
    height: 140px;
  }
`;

const PreviewAlreadyGranted = () => {
  return (
    <LicenseAlreadyGrantedContainer>
      <h2>You Have Already Signed Up For This Preview</h2>
      <p>Use the button below to access your preview</p>
      <PrimaryButton data-qa-hook="redirectToSubscription" onClick={() => windowService.redirectTo('/bible-studies')}>
        Go to Bible Studies
      </PrimaryButton>
    </LicenseAlreadyGrantedContainer>
  );
};

const LicenseAlreadyGrantedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Grid._3};

  > button {
    width: 100%;
    max-width: 280px;

    &:first-of-type {
      margin: ${Grid._7} 0 ${Grid._3} 0;
    }
  }
`;

const LicenseAlreadyGranted = ({ subscriptionUrl }) => {
  return (
    <LicenseAlreadyGrantedContainer>
      <h2>You Have Already Signed Up For This Trial</h2>
      <p>Use the button below to get started with your subscription</p>
      <PrimaryButton
        data-qa-hook="redirectToSubscription"
        onClick={() => windowService.openUrlInSameTab(subscriptionUrl)}
      >
        View Subscription Options
      </PrimaryButton>
      <LinkStyleButton
        data-qa-hook="redirectToBibleStudies"
        onClick={() => {
          windowService.redirectTo('#/bible-studies');
        }}
      >
        Explore Other Bible Studies
      </LinkStyleButton>
    </LicenseAlreadyGrantedContainer>
  );
};

LicenseAlreadyGranted.propTypes = {
  subscriptionUrl: PropTypes.string.isRequired,
};

const useAuthRedirect = () => {
  const [angularScope] = useAngularScope();

  const redirectToAuth = (isLogin, email, returnLocation = window.location.hash) =>
    angularScope.$emit('REDIRECT_TO_LIFEWAY', isLogin ? 'login' : 'register', returnLocation, email);

  const login = (email, returnLocation) => redirectToAuth(true, email, returnLocation);
  const register = (email, returnLocation) => redirectToAuth(false, email, returnLocation);

  return { login, register };
};

const useOnboardingParams = () => {
  const params = useParams();
  return { ...params, step: Number(params.step) ?? 1 };
};

const Onboarding = () => {
  const user = useUser();
  const { login, register } = useAuthRedirect();
  const { brand } = useCurriculum();
  const { step } = useOnboardingParams();
  const isPreview = !!brand.previewOptions;
  const { datastore, updateDatastore, deleteDatastore } = useCurriculumOnboardingDatastore(brand.code, {
    onboardingType: isPreview ? 'preview' : 'trial',
  });
  const { trackEvent, trackingEvents } = useEventTracking();
  const [status, setStatus] = useState({ isError: false, isAlreadyGranted: false });

  const getStepUrl = step => `/curriculum/onboarding/${brand.code}/${step}`;
  const goToStep = step => windowService.redirectTo(getStepUrl(step));

  const grantLicenses = (userId, orgId, zipCode) => {
    const { items } = datastore;

    const onTrialGranted = events => {
      deleteDatastore();

      const licenseItemNumbers = events.map(e => e.licenseItemNumber);
      cacheService.set('pairedItemNumbers', licenseItemNumbers);

      items.forEach(({ name: ageCategory }) => {
        trackEvent(trackingEvents.CURRICULUM_TRIAL_GRANTED, { brandCode: brand.code, ageCategory });
      });
      alertService.showOnNextPage(`${isPreview ? 'Preview' : 'Trial'} Granted`);
      windowService.redirectTo('/bible-studies');
    };

    Promise.allSettled(
      items.map(({ itemNumber }) => licenseService.grantTrialLicense(userId, orgId, itemNumber, zipCode, userId))
    ).then(responses => {
      const fulfilledResponses = responses.filter(res => res.status === 'fulfilled');
      if (fulfilledResponses.length === 0) {
        if (responses.some(res => res.reason?.reason === 'AlreadyGranted')) {
          return setStatus({ isAlreadyGranted: true });
        }
        return setStatus({ isError: true });
      }
      return onTrialGranted(fulfilledResponses.map(res => res.value));
    });
  };

  const handleAgeGroups = items => {
    updateDatastore({ items });
    goToStep(user ? 3 : 2);
  };

  const handleEmail = email => {
    userService
      .getUserIdByEmail(email)
      .then(() => login(email, getStepUrl(3)))
      .catch(() => {
        updateDatastore({ email });
        goToStep(3);
      });
  };

  const handleChurch = church => {
    updateDatastore({ church });
    goToStep(4);
  };

  const completeOnboarding = () => {
    if (!user) return register(datastore.email);

    const { church } = datastore;

    if (church.id) return grantLicenses(user.userId, church.id, church.zip);

    generateUUID(id =>
      orgService
        .createOrg(id, church.name, user.userId)
        .then(() => grantLicenses(user.userId, id, church.zip))
        .catch(handleError)
    );
  };

  useEffect(() => {
    if (brand.hidden) {
      windowService.redirectTo('/bible-studies');
    }

    if (step === 4) {
      completeOnboarding();
    }
  }, []);

  return (
    <div className="pb-64">
      <BodyStyle background={brand.onboarding?.background} />
      <Header>
        <img src={brand.imgSrc} alt={brand.name} />
      </Header>
      <ContentContainer>
        {status.isAlreadyGranted ? (
          isPreview ? (
            <PreviewAlreadyGranted />
          ) : (
            <LicenseAlreadyGranted subscriptionUrl={brand.onboarding?.subscriptionUrl} />
          )
        ) : status.isError ? (
          <ErrorMessage />
        ) : step === 2 ? (
          <EmailView data={datastore} handler={handleEmail} />
        ) : step === 3 ? (
          <ChurchView data={datastore} handler={handleChurch} />
        ) : step === 4 ? (
          <LoadingState>
            <h4>Finalizing onboarding process</h4>
          </LoadingState>
        ) : (
          <AgeGroupsView data={datastore} handler={handleAgeGroups} />
        )}
      </ContentContainer>
    </div>
  );
};

Onboarding.propTypes = {};

export default Onboarding;
