import { AnalyticsAdapter } from './AnalyticsAdapter';

declare global {
  interface Window {
    Intercom: (...args: any[]) => void;
  }
}

type LicensePairedData = {
  item: { number: string };
  curriculum: { brand: { code: string }; curriculum: { name: string } };
};

type CurriculumTrialGrantedData = {
  brandCode: string;
  ageCategory: string;
};

export class IntercomAdapter extends AnalyticsAdapter {
  #callIntercom(...args: any[]) {
    try {
      window.Intercom(...args);
    } catch (e) {
      console.error('Intercom event failed', e);
    }
  }

  #sendIntercomEvent(eventName: string, data: any) {
    this.#callIntercom('trackEvent', eventName, data);
  }

  handleLicensePaired(data: LicensePairedData) {
    const itemNumber = data.item.number;
    const brandCode = data.curriculum.brand.code;
    const ageCategory = data.curriculum.curriculum?.name;

    this.#sendIntercomEvent('license_paired', { itemNumber });

    if (brandCode && ageCategory) {
      this.#sendIntercomEvent(`${brandCode.toLowerCase()}_${ageCategory.toLowerCase()}_paired`, {
        brandCode,
        ageCategory,
      });
    }
  }

  handleCurriculumTrialGranted(data: CurriculumTrialGrantedData) {
    if (!data.brandCode && !data.ageCategory) return console.error('Invalid event structure');

    const { brandCode, ageCategory } = data;
    this.#sendIntercomEvent(`${brandCode.toLowerCase()}_${ageCategory.toLowerCase()}_trial_granted`, {
      brandCode,
      ageCategory,
    });
  }
}
