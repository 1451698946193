import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type, Breakpoints } from '../StyleGuide';
import { useUser } from '../authentication';
import useOrg from '../hooks/useOrg';
import { ActionRowButton } from '../components/Buttons';
import { Container, ContainerFluid } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import Breadcrumbs from '../components/Breadcrumbs';
import ConfirmationModal from '../components/ConfirmationModal';
import SectionHeader from '../components/SectionHeader';
import { Label } from '../components/FormElements';
import MemberProfileCard from './MemberProfileCard';
import AssignmentCard from './AssignmentCard';
import useModal from '../hooks/useModal';
import trainingService from '../services/trainingService';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import orgService, { getUserId, getUserEmail } from '../services/orgService';
import workflowService from '../services/workflowService';
import curriculumService from '../services/curriculumService';
import { handleError } from '../utils/apiUtils';

const CURRICULUM_PREFIXES = ['The Gospel Project:', 'Explore The Bible:', 'Bible Studies For Life:', 'YOU:'];

const isCurriculumGroup = name => {
  return (
    CURRICULUM_PREFIXES.findIndex(prefix => {
      return name.indexOf(prefix) > -1;
    }) > -1
  );
};

const PersonContainer = styled(Container)`
  display: flex;
  margin: ${Grid._5} auto;
  flex-direction: column;

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    flex-direction: row;
  }
`;

const Header = styled.h2`
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._5};
  font-weight: ${Type.Weight.bold};
  overflow-wrap: break-word;
  margin: 0;
`;

const NoTrainingAssigned = styled.p`
  color: ${Type.Color.mediumDark};
  font-size: ${Type.Scale._3};
  margin: 0 0 0 ${Grid._4};
`;

const PersonDataContainer = styled(ContainerFluid)`
  padding: ${Grid._4};

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    padding: ${Grid._4} ${Grid._8};
  }
`;

const PersonDataSectionContainer = styled.div`
  margin-bottom: ${Grid._8};
`;

const PersonDataSection = ({ title, fullWidth = false, ...props }) => {
  return (
    <PersonDataSectionContainer>
      <SectionHeader title={title} />
      <div className={`grid-container grid-col-1 grid-sm-col-${fullWidth ? '1' : '2'} grid-gap-24`} {...props} />
    </PersonDataSectionContainer>
  );
};

PersonDataSection.propTypes = {
  title: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

const PersonDataButton = ({ disabled, link, children, ...props }) => {
  const onClick = () => {
    if (disabled) {
      return;
    }

    windowService.redirectTo(link);
  };

  return (
    <div className="grid-col-span-2 grid-sm-col-span-1">
      <ActionRowButton onClick={onClick} className={disabled ? 'disabled' : ''} {...props}>
        {children}
        {!disabled && <i className="fas fa-chevron-right" />}
      </ActionRowButton>
    </div>
  );
};

PersonDataButton.propTypes = {
  link: PropTypes.string,
  disabled: PropTypes.bool,
};

const UnassignTrainingModal = ({ assignment, ...props }) => {
  return (
    <ConfirmationModal
      buttonActionText="Unassign"
      buttonType="danger"
      title="Unassign Training"
      prompt={<span>Are you sure you want to unassign this training?</span>}
      {...props}
    >
      <p>
        <Label>{assignment.trainingName}</Label>
      </p>
      <p className="help-block">
        <i className="icon ion-android-alert"></i> All Progress will be lost
      </p>
    </ConfirmationModal>
  );
};

UnassignTrainingModal.propTypes = {
  assignment: PropTypes.object.isRequired,
};

const RemoveMemberModal = ({ member, handleDismiss }) => {
  const user = useUser();
  const org = useOrg();

  const removeMember = () => {
    const removePendingOrAcceptedMember = () =>
      member.isPending
        ? orgService.removePendingMember(
            member.inviteId.id,
            getUserEmail(member),
            user.lastSelectedAccount,
            user.userId
          )
        : orgService.removeMembers(user.lastSelectedAccount, [member], user.userId);

    removePendingOrAcceptedMember()
      .then(() => {
        alertService.showOnNextPage('Person removed');
        windowService.redirectTo('/people');
      })
      .catch(error => {
        if (error.code === 'NoAdminRemaining') {
          console.error(error);
          alertService.showError('Removing this person will leave no administrator in this organization.');
        } else {
          handleError(error);
        }

        handleDismiss();
      });
  };

  return (
    <ConfirmationModal
      buttonActionText="Remove"
      buttonType="danger"
      title="Remove Person"
      prompt={<span>Are you sure you want to remove this person from {org.name}?</span>}
      handleSubmit={removeMember}
      handleDismiss={handleDismiss}
    >
      <p>
        <Label>{member.name}</Label>
      </p>
      <p>
        <Label>{getUserEmail(member)}</Label>
      </p>
      <p>
        Doing so will cause them to lose access to any content that your organization subscribes to. If you want to add
        them back to your organization, you will have to re-add them.
      </p>
      <p className="help-block">
        <i className="icon ion-android-alert"></i> This action cannot be undone
      </p>
    </ConfirmationModal>
  );
};

RemoveMemberModal.propTypes = {
  member: PropTypes.object.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default function Person() {
  const user = useUser();
  const [data, setData] = useState({ isLoading: true });
  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'People', route: '#/people' },
  ]);
  const { id: memberId } = useParams();
  const orgId = user.lastSelectedAccount;
  const { memberData = {}, isLoading, isError } = data;
  const { member, roles, curricula, groups, assignments, workflow } = memberData;

  const [modal, openModal] = useModal((type, payload, dismissModal) => {
    switch (type) {
      case 'unassignTraining':
        return (
          <UnassignTrainingModal
            assignment={payload.assignment}
            handleSubmit={() => unassignTraining(payload.assignment, dismissModal)}
            handleDismiss={dismissModal}
          />
        );
      case 'removeMember':
        return <RemoveMemberModal member={payload.member} handleDismiss={dismissModal} />;
      default:
        return null;
    }
  });

  const getAssignmentsForMember = member => {
    if (!member.isPending) {
      return trainingService.getAssignmentsForUser(getUserId(member)).then(assignments => ({ assignments }));
    }

    return workflowService.getWorkflowById(member.workflowId).then(workflow => {
      const assignments = workflow.entities.filter(entity => entity._type === 'Assign');
      return { workflow, assignments };
    });
  };

  const loadMember = () =>
    orgService.getMember(memberId).then(member =>
      getAssignmentsForMember(member).then(assignmentsAndWorkflow =>
        curriculumService.getCollectionGroups(orgId).then(collectionGroups => {
          const roles = member.roles;
          const groups = member.groups ? member.groups.filter(g => !isCurriculumGroup(g.name)) : [];
          const curriculumGroups = member.groups ? member.groups.filter(g => isCurriculumGroup(g.name)) : [];
          const collections = curriculumGroups.map(cg =>
            collectionGroups.find(collection => collection.id === cg.id.id)
          );
          return Promise.all(
            collections.map(collection =>
              curriculumService.getIndividualCurriculum(collection.curriculum.curriculumId).then(curriculum => ({
                ...collection,
                brandCode: curriculum.brandCode,
                age: curriculum.name,
                curriculumId: collection.curriculum.curriculumId,
                collectionId: collection.id,
              }))
            )
          ).then(curricula => ({
            member: {
              ...member,
              isYou: member._id?.id === user.userId,
            },
            roles,
            curricula,
            groups,
            ...assignmentsAndWorkflow,
          }));
        })
      )
    );

  const unassignTraining = (assignment, dismissModal) => {
    const unassignFromPendingOrAcceptedMember = () =>
      member.isPending
        ? workflowService.removeStepFromWorkflow(workflow.id, assignment.id, user.userId)
        : trainingService.unassignTraining(assignment, user.userId);

    unassignFromPendingOrAcceptedMember()
      .then(() => {
        setData(data => ({
          memberData: {
            ...data.memberData,
            assignments: data.memberData.assignments.filter(a => a.id !== assignment.id),
          },
          isLoading: false,
        }));
        alertService.show('Training unassigned');
      })
      .catch(handleError)
      .finally(dismissModal);
  };

  const onRemoveMember = () => {
    openModal('removeMember', { member });
  };

  useEffect(() => {
    loadMember()
      .then(memberData => {
        const memberName = memberData.member.name;
        setData({ memberData, isLoading: false });
        setCrumbs([
          ...crumbs,
          { name: memberName && memberName.trim() !== '' ? memberName : getUserEmail(memberData.member) },
        ]);
      })
      .catch(err => {
        setData({ isLoading: false, isError: true });
        console.error(err);
        alertService.showError('Person not found. Please try again.');
      });
  }, []);

  return (
    <>
      {<Breadcrumbs crumbs={crumbs} />}
      <PersonContainer data-qa-hook="peopleView">
        {isLoading ? (
          <Container>
            <LoadingState />
          </Container>
        ) : isError ? (
          <Container>
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          </Container>
        ) : (
          <>
            <MemberProfileCard orgId={orgId} member={member} onRemoveMember={onRemoveMember} />
            <PersonDataContainer>
              {roles?.length > 0 && (
                <PersonDataSection title="Roles">
                  {roles?.map(role => (
                    <PersonDataButton data-qa-hook="rolePill" key={role.id.id} link={`/roles/${role.id.id}`}>
                      {role.name}
                    </PersonDataButton>
                  ))}
                </PersonDataSection>
              )}
              {groups?.length > 0 && (
                <PersonDataSection title="Groups">
                  {groups?.map(group => (
                    <PersonDataButton
                      data-qa-hook="groupPill"
                      key={group.id.id}
                      link={`/groups/${group.id.id}/${orgId}`}
                    >
                      {group.name}
                    </PersonDataButton>
                  ))}
                </PersonDataSection>
              )}
              {curricula?.length > 0 && (
                <PersonDataSection title="Curriculum">
                  {curricula?.map(c => (
                    <PersonDataButton
                      data-qa-hook="curriculumListItem"
                      key={c.id}
                      link={`/manage-bible-study/people/collection/${c.brandCode}/${c.age}/${c.curriculumId}/${c.collectionId}`}
                    >
                      {c.name}
                    </PersonDataButton>
                  ))}
                </PersonDataSection>
              )}
              <PersonDataSection title="Assignments" fullWidth={true}>
                {assignments?.length > 0 ? (
                  assignments.map(a => (
                    <AssignmentCard
                      key={a.id}
                      user={member}
                      assignment={a}
                      unassignTraining={() => openModal('unassignTraining', { assignment: a })}
                    />
                  ))
                ) : (
                  <NoTrainingAssigned>No Training Assigned</NoTrainingAssigned>
                )}
              </PersonDataSection>
            </PersonDataContainer>
          </>
        )}
      </PersonContainer>
      {modal}
    </>
  );
}
