import React, { useState, useEffect } from 'react';
import { parseISO } from 'date-fns';
import licenseService from '../../services/licenseService';
import curriculumService from '../../services/curriculumService';
import { useUser } from '../../authentication';

const useFetchSubscriptions = () => {
  const user = useUser();
  const [data, setData] = useState({
    isLoading: true,
    isError: false,
    isAccessDenied: false,
    activeLicenses: [],
    expiredLicenses: [],
  });

  const setErrorState = () => setData({ isLoading: false, isError: true });
  const setUnauthorizedState = () => setData({ isLoading: false, isAccessDenied: true });

  const combineSubscriptionLicenses = licenses =>
    licenses.reduceRight(
      (acc, cur) =>
        acc.find(a => a.id === cur.id || (cur.subscription?.id && a.subscription?.id === cur.subscription?.id))
          ? acc
          : [...acc, cur],
      []
    );

  const sortLicensesByStartDate = licenses =>
    licenses.toSorted((l1, l2) => parseISO(l2.startDate) - parseISO(l1.startDate));

  const separateActiveAndExpiredLicenses = (licenses, curriculum) =>
    licenses.reduce(
      ({ active, expired }, license) => {
        const licenseWithCurriculum = {
          ...license,
          curriculum: licenseService.getCorrespondingCurriculum(license, curriculum),
        };
        return license.expired === true
          ? { active: active, expired: [...expired, licenseWithCurriculum] }
          : { active: [...active, licenseWithCurriculum], expired };
      },
      { active: [], expired: [] }
    );

  useEffect(() => {
    if (!user.permissions.includes('Account View')) {
      return setUnauthorizedState();
    }

    Promise.all([licenseService.getPairedLicenses(user.lastSelectedAccount), curriculumService.getPricing()])
      .then(([pairedLicenses, curriculum]) => {
        const { active, expired } = separateActiveAndExpiredLicenses(
          sortLicensesByStartDate(combineSubscriptionLicenses(pairedLicenses)),
          curriculum
        );
        setData(prev => ({ ...prev, isLoading: false, activeLicenses: active, expiredLicenses: expired }));
      })
      .catch(reason => {
        console.error(reason);
        setErrorState();
      });
  }, []);

  return data;
};

export default useFetchSubscriptions;
