import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breakpoints } from '../StyleGuide';
import { TileButton } from '../components/Buttons';
import AnnualSubscriptionModal, { AnnualUpgradeActionWrapper } from '../curriculum/AnnualSubscriptionModal';
import licenseService from '../services/licenseService';
import windowService from '../services/windowService';
import alertService from '../services/AlertService';
import useModal from '../hooks/useModal';
import LicenseDetails from './LicenseDetails';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import ResumeSubscriptionModal from './ResumeSubscriptionModal';

const LicenseContainer = styled.div`
  &:not([disabled]) {
    cursor: pointer;

    &:hover {
      h4 {
        text-decoration: underline;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr auto;
  }
`;

const ButtonsContainer = styled.div`
  align-content: center;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-auto-flow: column;
  }

  button {
    margin: 0;
  }
`;

const LicenseListItem = props => {
  const [license, setLicense] = useState(props.license);

  const updateSubscriptionStatus = useCallback(status => {
    if (!status) return;

    setLicense(prev => ({
      ...prev,
      subscription: {
        ...prev.subscription,
        status,
      },
    }));
  }, []);

  const [modal, openModal] = useModal((type, payload, dismissModal) => {
    switch (type) {
      case 'cancel':
        return (
          <CancelSubscriptionModal
            license={license}
            updateSubscriptionStatus={updateSubscriptionStatus}
            handleDismiss={dismissModal}
          />
        );
      case 'resume':
        return (
          <ResumeSubscriptionModal
            license={license}
            updateSubscriptionStatus={updateSubscriptionStatus}
            handleDismiss={dismissModal}
          />
        );
      case 'annual':
        return (
          <AnnualSubscriptionModal
            subscription={payload}
            onUpgradeComplete={handleUpgradedSubscription}
            handleDismiss={dismissModal}
          />
        );
      default:
        return null;
    }
  });

  const canManage = !license.expired && !licenseService.isIndividualLicense(license);
  const canEdit = !license.expired && licenseService.canUpgradeDowngrade(license);
  const canCancel = !license.expired && licenseService.isActiveSubscription(license);
  const canResume = !license.expired && licenseService.isInactiveSubscription(license);
  const canResubscribe = license.expired && licenseService.hasSubscription(license);
  const canSwitchToAnnual =
    !license.expired &&
    licenseService.isActiveSubscription(license) &&
    licenseService.isMonthlySubscription(license) &&
    licenseService.isCurriculumLicense(license);

  function handleUpgradedSubscription(newSubscription) {
    setLicense(prev => ({
      ...prev,
      subscription: {
        ...prev.subscription,
        ...newSubscription,
      },
    }));
    alertService.show('Subscription Updated');
  }

  const showModal = (e, ...args) => {
    e.stopPropagation();
    openModal(...args);
  };

  const openMyLifewaySubscriptions = e => {
    e.stopPropagation();
    windowService.openUrl('https://my.lifeway.com/subscriptions');
  };

  const buttonActions = [
    {
      condition: canEdit,
      iconClass: 'fas fa-pen',
      buttonText: 'Edit Subscription',
      onClick: () => {},
    },
    {
      condition: canCancel,
      iconClass: 'fas fa-ban',
      buttonText: 'Cancel Subscription',
      onClick: event => showModal(event, 'cancel'),
    },
    {
      condition: canResume,
      iconClass: 'fas fa-credit-card',
      buttonText: 'Resume Subscription',
      onClick: event => showModal(event, 'resume'),
    },
    {
      condition: canResubscribe,
      iconClass: 'fas fa-credit-card',
      buttonText: 'Resubscribe',
      onClick: openMyLifewaySubscriptions,
    },
  ];

  return (
    <>
      <LicenseContainer
        data-qa-hook="subscriptionItem"
        className="grid-container grid-gap-24"
        disabled={!canManage}
        onClick={() => (canManage ? windowService.redirectTo(`/org/purchases-and-subscriptions/${license.id}`) : {})}
      >
        <LicenseDetails license={license} showAdditionalDetails />
        <ButtonsContainer className="grid-container grid-gap-16">
          {canSwitchToAnnual && (
            <AnnualUpgradeActionWrapper subscription={license.subscription}>
              {annualSubscription => (
                <TileButton
                  data-qa-hook="upgradeToAnnualSubscriptionButton"
                  onClick={e => showModal(e, 'annual', annualSubscription)}
                >
                  <i className="fas fa-cog"></i> Switch to Annual
                </TileButton>
              )}
            </AnnualUpgradeActionWrapper>
          )}

          {buttonActions.map(
            action =>
              action.condition && (
                <TileButton key={action.buttonText} onClick={action.onClick}>
                  <i className={action.iconClass} /> {action.buttonText}
                </TileButton>
              )
          )}
        </ButtonsContainer>
      </LicenseContainer>
      {modal}
    </>
  );
};

LicenseListItem.propTypes = {
  license: PropTypes.shape({
    id: PropTypes.string,
    expired: PropTypes.bool,
    subscription: PropTypes.object,
  }).isRequired,
};

export default LicenseListItem;
