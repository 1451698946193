import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '../StyleGuide';
import { Container } from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import SectionHeader from '../components/SectionHeader';
import LoadingState from '../components/LoadingState';
import AccessDenied from '../components/AccessDenied';
import ErrorMessage from '../components/ErrorMessage';
import LicenseListItem from './LicenseListItem';
import useFetchSubscriptions from './hooks/useFetchSubscriptions';

const LicenseList = styled.div`
  display: grid;
  grid-gap: ${Grid._7};
  margin-bottom: ${Grid._11};
`;

const LicenseListSection = ({ title, licenses }) => (
  <>
    <SectionHeader title={title} />
    <LicenseList data-qa-hook={`licenseList${title}`}>
      {licenses.map(license => (
        <LicenseListItem key={license.id} license={license} />
      ))}
    </LicenseList>
  </>
);

LicenseListSection.propTypes = {
  title: PropTypes.string.isRequired,
  licenses: PropTypes.array.isRequired,
};

export function ManageSubscriptions() {
  const { isLoading, isError, isAccessDenied, activeLicenses, expiredLicenses } = useFetchSubscriptions();
  const crumbs = [{ name: 'Organization', route: '#/org' }, { name: 'Purchases and Subscriptions' }];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container data-qa-hook="subscriptionsView">
        <h2 className="mt-40 mb-48">Purchases and Subscriptions</h2>
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : isAccessDenied ? (
          <AccessDenied />
        ) : (
          <>
            {!activeLicenses.length && !expiredLicenses.length && <p>No Purchases or Subscriptions</p>}
            {!!activeLicenses.length && <LicenseListSection title="Active" licenses={activeLicenses} />}
            {!!expiredLicenses.length && <LicenseListSection title="Expired" licenses={expiredLicenses} />}
          </>
        )}
      </Container>
    </>
  );
}
