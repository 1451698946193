import { AnalyticsAdapter } from '../models/AnalyticsAdapter';
import { IntercomAdapter } from '../models/IntercomAdapter';
import { ActiveCampaignAdapter } from '../models/ActiveCampaignAdapter';
import { FullStoryAdapter } from '../models/FullStoryAdapter';

enum TrackingEvent {
  LICENSE_PAIRED = 'handleLicensePaired',
  CURRICULUM_TRIAL_GRANTED = 'handleCurriculumTrialGranted',
  ITEM_CARTED = 'handleItemCarted',
  PAGE_CHANGED = 'handlePageChanged',
  TIMELINE_SEGMENT_TOGGLED = 'handleTimelineSegmentToggled',
}

class EventTrackerClass {
  readonly events = TrackingEvent;

  constructor(private readonly adapters: AnalyticsAdapter[]) {}

  trackEvent(event: TrackingEvent, data: any) {
    if (!Object.values(TrackingEvent).includes(event)) {
      console.error('Invalid tracking event');
      return [];
    }

    return this.adapters.map(adapter => adapter[event](data)).filter((handler: unknown) => handler instanceof Promise);
  }
}

export const EventTracker = new EventTrackerClass([
  new IntercomAdapter(),
  new ActiveCampaignAdapter(),
  new FullStoryAdapter(),
]);
