import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Color, Type, Border, Transition } from '../StyleGuide';
import { SmallRoundedIconSelect } from '../components/FormElements';
import StepIcons from '../StepIcons';
import VideoPreviewModal from '../components/VideoPreviewModal';
import UnlockTrainingModal from './UnlockTrainingModal';
import windowService from '../services/windowService';
import { useUser } from '../authentication';

const Steps = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Step = styled.li`
  display: flex;
  align-items: center;
  padding: ${Grid._5} ${Grid._4};
  font-size: ${Type.Scale._3};
  font-weight: ${Type.Weight.semibold};
  color: ${Type.Color.dark};
  border-bottom: 1px solid ${Color.Gray._10};
  border-radius: ${Border.radius};
  transition: ${Transition.fast};
  line-height: ${Type.Leading.taller};
  /* cursor: pointer; */
  i.step-icon {
    margin-right: ${Grid._5};
  }
  span {
    flex: 1;
    margin-right: ${Grid._4};
  }
  /* &:hover {
    background-color: ${Color.Gray._05};
    span {
      text-decoration: underline;
    }
  } */
  &:last-child {
    border-bottom: none;
  }
`;

const CourseSteps = ({ course, localStorage }) => {
  const user = useUser();
  const [showUnlockTraining, setShowUnlockTraining] = useState(false);
  const [videoPreviewModal, setVideoPreviewModal] = useState({ show: false, name: '', videoId: '' });

  const CourseStep = ({ step }) => {
    const name = step.task ? step.task.taskName : step.taskName;
    const type = step.task ? step.task._type : step._type;
    const showAddToAnotherCourse = user && user.hasBuildPermission && !course.reports.length;
    const showVideoPreview = user && user.canTake && (type === 'ThirdPartyVideo' || type === 'Video');
    const videoId = type === 'ThirdPartyVideo' ? step.videoUrl : step.videoId;

    const handleQuickAction = action => {
      if (action === 'addTo') {
        if (user.canAccess) {
          localStorage.stepToAdd = {
            task: step,
            taskList: course.id,
            origin: window.location.hash,
          };
          windowService.redirectTo(`#/add-step/${step._id.id}`);
        } else {
          setShowUnlockTraining(true);
        }
      } else if (action === 'preview') {
        setVideoPreviewModal({ show: true, name: name, type: type, videoId: videoId });
      }
    };

    return (
      <Step data-qa-hook="searchPreviewTrainingTask" key={step._id.id}>
        <i className={`step-icon ${StepIcons[type]}`} />
        <span data-qa-hook="searchPreviewTrainingTaskName">{name}</span>
        {(showAddToAnotherCourse || showVideoPreview) && (
          <SmallRoundedIconSelect
            data-qa-hook="quickActionsDropdown"
            name="quickActions"
            id="quickActions"
            value={''}
            onChange={e => handleQuickAction(e.target.value)}
          >
            <option value="" defaultValue>
              &#183;&#183;&#183;
            </option>
            {showAddToAnotherCourse && <option value="addTo">Add to Another Course</option>}
            {showVideoPreview && <option value="preview">Preview Video</option>}
          </SmallRoundedIconSelect>
        )}
      </Step>
    );
  };

  return (
    <>
      <Steps>
        {course.tasks.map(step => (
          <CourseStep key={step._id.id} step={step} />
        ))}
      </Steps>
      {showUnlockTraining && (
        <UnlockTrainingModal itemNumber={course.itemNumber.value} handleDismiss={() => setShowUnlockTraining(false)} />
      )}
      {videoPreviewModal.show && (
        <VideoPreviewModal
          name={videoPreviewModal.name}
          type={videoPreviewModal.type}
          videoId={videoPreviewModal.videoId}
          handleDismiss={() => setVideoPreviewModal({ show: false, name: '', videoId: '' })}
        />
      )}
    </>
  );
};

CourseSteps.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseSteps;
