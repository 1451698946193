import React from 'react';

type Props = {
  icon?: string;
  title: string;
  description: string;
  children?: React.ReactNode;
};

const EmptyState = ({ icon, title, description, children }: Props) => (
  <div data-qa-hook="emptyStateUI" className="empty-state-ui">
    {icon && <span className={'fas ' + icon}></span>}
    <h4>{title}</h4>
    <div>
      <p className="text-center">{description}</p>
    </div>
    {children}
  </div>
);

export default EmptyState;
